import { Injectable } from '@angular/core';
import { ajax } from 'jquery';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
BanderaLogin : boolean = false;
public local=JSON.parse(localStorage.getItem('user'));
ULTIMO = 'Cargando.....';
FOLDER = 'Despacho'
Id_Usuario;
Id_Client;
idRole;
public appPages =[
  { title: 'Principal', url: '/principal', icon: 'speedometer' },
  { title: 'Mapa General', url: '/mapa', icon: 'map' },]
Aulas = [];
Aulas2 = [];
Aulas3 = [];
AULASEL = [];

mapStyle:any [] =[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];
  constructor(private http: HttpClient) { }

  consultar(datos, respuesta) {
    console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      //url: 'http://192.168.0.10:80/DatabaseMON.php',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn(errorThrown);
        console.warn(jqXhr);
        console.warn(textStatus);
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  //messageUrl = 'http://192.168.0.10:8001/';
  messageUrl = 'https://www.php.engenius.com.co/DatabaseLIWA.php';

  sendMessage(messageModel: any): Observable<any> {
    return this.http.post(this.messageUrl, messageModel);
  }
}
